import UnitsInServiceComponent from '@/components/units-in-service/UnitsInServiceComponent.vue'
import UnitsStateComponent from '@/components/units-state/UnitsStateComponent.vue'
// import { SECTOR_KEY } from '@colven/common-domain-lib/lib'

export default {
  name: 'TransportDashboard',
  components: {
    UnitsInServiceComponent,
    UnitsStateComponent
  },
  data: () => ({
    unitsInServiceSector: 'TRANSPORT',
    unitsStateSector: 'TRANSPORT'
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
  }
}
